"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.INITIAL_STATE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _variables = require("../variables");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var INITIAL_STATE = exports.INITIAL_STATE = {
  createUnitError: null,
  createUnitLoading: false,
  listUnitsError: null,
  getUnitError: null,
  removeResidentError: null,
  editResidentError: null,
  addResidentError: null,
  loading: false,
  shouldUpdate: false,
  amenityCardList: [],
  loadingAmenityCards: false,
  listAmenityCardsError: false,
  createAmenityCardError: false,
  deleteAmenityCardError: false,
  expireAmenityCardError: false,
  editAmenityCardError: false,
  loadingSearchUser: false,
  searchUserError: false,
  errorDeleteUnit: false,
  errorEditUnit: false,
  loadingAddResidentToUnit: false,
  loadingDeleteUnit: false,
  loadingEditUnit: false,
  loadingFloorsList: false,
  loadingLinesList: false,
  loadingSendAmenityCardLink: false,
  loadingEditResident: false,
  sendAmenityCardLinkError: false,
  list: [],
  floorsList: [],
  linesList: [],
  unit: {}
};
var _default = exports["default"] = function _default() {
  var _action$payload$data, _action$payload, _action$payload$data2, _action$payload2, _action$payload$data3, _action$payload3, _action$payload$data4, _action$payload4, _action$payload$data5, _action$payload5, _action$payload$data6, _action$payload6, _action$payload$data7, _action$payload7, _action$payload$data8, _action$payload8, _action$payload$data9, _action$payload9, _action$payload$data10, _action$payload10, _action$payload$data11, _action$payload11, _action$payload$data12, _action$payload12, _action$payload$data13, _action$payload13, _action$payload$data14, _action$payload14, _action$payload$data15, _action$payload15, _action$payload$data16, _action$payload16, _action$payload$data17, _action$payload17, _action$payload$data18, _action$payload18, _action$payload$data19, _action$payload19, _action$payload$data20, _action$payload20, _action$payload$data21, _action$payload21, _action$payload$data22, _action$payload22, _action$payload$data23, _action$payload23, _action$payload$data24, _action$payload24;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _variables.CREATE_UNIT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        createUnitError: null,
        createUnitLoading: true
      });
    case _variables.CREATE_UNIT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        createUnitLoading: false,
        createUnitError: null,
        unit: (_action$payload$data = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data) !== null && _action$payload$data !== void 0 ? _action$payload$data : {}
      });
    case _variables.CREATE_UNIT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        createUnitLoading: false,
        createUnitError: (_action$payload$data2 = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data) !== null && _action$payload$data2 !== void 0 ? _action$payload$data2 : {}
      });
    case _variables.LIST_UNITS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        listUnitsError: null,
        loading: true
      });
    case _variables.LIST_UNITS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        listUnitsError: null,
        list: (_action$payload$data3 = action === null || action === void 0 || (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.data) !== null && _action$payload$data3 !== void 0 ? _action$payload$data3 : []
      });
    case _variables.LIST_UNITS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        listUnitsError: (_action$payload$data4 = action === null || action === void 0 || (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.data) !== null && _action$payload$data4 !== void 0 ? _action$payload$data4 : {}
      });
    case _variables.LIST_UNIT_FLOORS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingFloorsList: true
      });
    case _variables.LIST_UNIT_FLOORS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingFloorsList: false,
        error: null,
        floorsList: (_action$payload$data5 = action === null || action === void 0 || (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.data) !== null && _action$payload$data5 !== void 0 ? _action$payload$data5 : []
      });
    case _variables.LIST_UNIT_FLOORS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingFloorsList: false,
        error: (_action$payload$data6 = action === null || action === void 0 || (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.data) !== null && _action$payload$data6 !== void 0 ? _action$payload$data6 : {}
      });
    case _variables.LIST_UNIT_LINES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingLinesList: true
      });
    case _variables.LIST_UNIT_LINES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingLinesList: false,
        error: null,
        linesList: (_action$payload$data7 = action === null || action === void 0 || (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.data) !== null && _action$payload$data7 !== void 0 ? _action$payload$data7 : []
      });
    case _variables.LIST_UNIT_LINES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingLinesList: false,
        error: (_action$payload$data8 = action === null || action === void 0 || (_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : _action$payload8.data) !== null && _action$payload$data8 !== void 0 ? _action$payload$data8 : {}
      });
    case _variables.GET_UNIT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        getUnitError: null
      });
    case _variables.GET_UNIT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        getUnitError: null,
        unit: (_action$payload$data9 = action === null || action === void 0 || (_action$payload9 = action.payload) === null || _action$payload9 === void 0 ? void 0 : _action$payload9.data) !== null && _action$payload$data9 !== void 0 ? _action$payload$data9 : {}
      });
    case _variables.GET_UNIT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        getUnitError: (_action$payload$data10 = action === null || action === void 0 || (_action$payload10 = action.payload) === null || _action$payload10 === void 0 ? void 0 : _action$payload10.data) !== null && _action$payload$data10 !== void 0 ? _action$payload$data10 : {}
      });
    case _variables.DELETE_UNIT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteUnit: true,
        errorDeleteUnit: null
      });
    case _variables.DELETE_UNIT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteUnit: false,
        errorDeleteUnit: null
      });
    case _variables.DELETE_UNIT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteUnit: false,
        errorDeleteUnit: (_action$payload$data11 = action === null || action === void 0 || (_action$payload11 = action.payload) === null || _action$payload11 === void 0 ? void 0 : _action$payload11.data) !== null && _action$payload$data11 !== void 0 ? _action$payload$data11 : {}
      });
    case _variables.EDIT_UNIT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditUnit: true,
        errorEditUnit: null
      });
    case _variables.EDIT_UNIT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditUnit: false,
        errorEditUnit: null
      });
    case _variables.EDIT_UNIT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditUnit: false,
        errorEditUnit: (_action$payload$data12 = action === null || action === void 0 || (_action$payload12 = action.payload) === null || _action$payload12 === void 0 ? void 0 : _action$payload12.data) !== null && _action$payload$data12 !== void 0 ? _action$payload$data12 : {}
      });
    case _variables.REMOVE_RESIDENT_FROM_UNIT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        removeResidentError: null
      });
    case _variables.REMOVE_RESIDENT_FROM_UNIT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        removeResidentError: null
      });
    case _variables.REMOVE_RESIDENT_FROM_UNIT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        removeResidentError: (_action$payload$data13 = action === null || action === void 0 || (_action$payload13 = action.payload) === null || _action$payload13 === void 0 ? void 0 : _action$payload13.data) !== null && _action$payload$data13 !== void 0 ? _action$payload$data13 : {}
      });
    case _variables.EDIT_RESIDENT_FROM_UNIT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        editResidentError: null
      });
    case _variables.EDIT_RESIDENT_FROM_UNIT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        editResidentError: null
      });
    case _variables.EDIT_RESIDENT_FROM_UNIT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        editResidentError: (_action$payload$data14 = action === null || action === void 0 || (_action$payload14 = action.payload) === null || _action$payload14 === void 0 ? void 0 : _action$payload14.data) !== null && _action$payload$data14 !== void 0 ? _action$payload$data14 : {}
      });
    case _variables.PATCH_RESIDENT_FROM_UNIT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditResident: true,
        editResidentError: null
      });
    case _variables.PATCH_RESIDENT_FROM_UNIT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditResident: false,
        editResidentError: null
      });
    case _variables.PATCH_RESIDENT_FROM_UNIT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditResident: false,
        editResidentError: (_action$payload$data15 = action === null || action === void 0 || (_action$payload15 = action.payload) === null || _action$payload15 === void 0 ? void 0 : _action$payload15.data) !== null && _action$payload$data15 !== void 0 ? _action$payload$data15 : {}
      });
    case _variables.ADD_RESIDENT_TO_UNIT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAddResidentToUnit: true,
        addResidentError: null
      });
    case _variables.ADD_RESIDENT_TO_UNIT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAddResidentToUnit: false,
        addResidentError: null
      });
    case _variables.ADD_RESIDENT_TO_UNIT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAddResidentToUnit: false,
        addResidentError: (_action$payload$data16 = action === null || action === void 0 || (_action$payload16 = action.payload) === null || _action$payload16 === void 0 ? void 0 : _action$payload16.data) !== null && _action$payload$data16 !== void 0 ? _action$payload$data16 : {}
      });
    case _variables.SEARCH_USER_BY_PHONE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSearchUser: true
      });
    case _variables.SEARCH_USER_BY_PHONE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSearchUser: false,
        searchUserError: null
      });
    case _variables.SEARCH_USER_BY_PHONE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSearchUser: false,
        searchUserError: (_action$payload$data17 = action === null || action === void 0 || (_action$payload17 = action.payload) === null || _action$payload17 === void 0 ? void 0 : _action$payload17.data) !== null && _action$payload$data17 !== void 0 ? _action$payload$data17 : {}
      });
    case _variables.LIST_AMENITY_CARDS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: true
      });
    case _variables.LIST_AMENITY_CARDS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: false,
        amenityCardList: (_action$payload$data18 = action === null || action === void 0 || (_action$payload18 = action.payload) === null || _action$payload18 === void 0 ? void 0 : _action$payload18.data) !== null && _action$payload$data18 !== void 0 ? _action$payload$data18 : [],
        listAmenityCardsError: false
      });
    case _variables.LIST_AMENITY_CARDS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: false,
        listAmenityCardsError: (_action$payload$data19 = action === null || action === void 0 || (_action$payload19 = action.payload) === null || _action$payload19 === void 0 ? void 0 : _action$payload19.data) !== null && _action$payload$data19 !== void 0 ? _action$payload$data19 : {}
      });
    case _variables.CREATE_AMENITY_CARDS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: true
      });
    case _variables.CREATE_AMENITY_CARDS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: false,
        createAmenityCardError: false
      });
    case _variables.CREATE_AMENITY_CARDS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: false,
        createAmenityCardError: (_action$payload$data20 = action === null || action === void 0 || (_action$payload20 = action.payload) === null || _action$payload20 === void 0 ? void 0 : _action$payload20.data) !== null && _action$payload$data20 !== void 0 ? _action$payload$data20 : {}
      });
    case _variables.EDIT_AMENITY_CARDS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: true
      });
    case _variables.EDIT_AMENITY_CARDS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: false,
        editAmenityCardError: false
      });
    case _variables.EDIT_AMENITY_CARDS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: false,
        editAmenityCardError: (_action$payload$data21 = action === null || action === void 0 || (_action$payload21 = action.payload) === null || _action$payload21 === void 0 ? void 0 : _action$payload21.data) !== null && _action$payload$data21 !== void 0 ? _action$payload$data21 : {}
      });
    case _variables.DELETE_AMENITY_CARD_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: true
      });
    case _variables.DELETE_AMENITY_CARD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: false,
        deleteAmenityCardError: false
      });
    case _variables.DELETE_AMENITY_CARD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: false,
        deleteAmenityCardError: (_action$payload$data22 = action === null || action === void 0 || (_action$payload22 = action.payload) === null || _action$payload22 === void 0 ? void 0 : _action$payload22.data) !== null && _action$payload$data22 !== void 0 ? _action$payload$data22 : {}
      });
    case _variables.EXPIRE_AMENITY_CARD_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: true
      });
    case _variables.EXPIRE_AMENITY_CARD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: false,
        expireAmenityCardError: false
      });
    case _variables.EXPIRE_AMENITY_CARD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAmenityCards: false,
        expireAmenityCardError: (_action$payload$data23 = action === null || action === void 0 || (_action$payload23 = action.payload) === null || _action$payload23 === void 0 ? void 0 : _action$payload23.data) !== null && _action$payload$data23 !== void 0 ? _action$payload$data23 : {}
      });
    case _variables.SEND_AMENITY_CARD_LINK_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSendAmenityCardLink: true
      });
    case _variables.SEND_AMENITY_CARD_LINK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSendAmenityCardLink: false,
        sendAmenityCardLinkError: false
      });
    case _variables.SEND_AMENITY_CARD_LINK_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSendAmenityCardLink: false,
        sendAmenityCardLinkError: (_action$payload$data24 = action === null || action === void 0 || (_action$payload24 = action.payload) === null || _action$payload24 === void 0 ? void 0 : _action$payload24.data) !== null && _action$payload$data24 !== void 0 ? _action$payload$data24 : {}
      });
    case _variables.UPDATED_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        shouldUpdate: false
      });
    case _variables.SHOULD_UPDATE_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        shouldUpdate: true
      });
    default:
      return state;
  }
};