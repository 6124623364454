"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestRepublishNotice = exports.requestListUpcomingNotices = exports.requestListNotices = exports.requestListExpiredNotices = exports.requestListBulletinsRequests = exports.requestListBulletins = exports.requestGetPreviewNotice = exports.requestGetNotice = exports.requestGetBulletin = exports.requestEditNoticeDraft = exports.requestDenyBulletin = exports.requestDeleteNoticeDraft = exports.requestCreateNoticeDraft = exports.requestCreateNotice = exports.requestCreateBulletin = exports.requestCancelHandout = exports.requestApproveBulletin = void 0;
var _utils = require("../../../utils");
var _variables = require("../variables");
var requestApproveBulletin = exports.requestApproveBulletin = function requestApproveBulletin(payload, meta) {
  return (0, _utils.buildAction)(_variables.APPROVE_BULLETIN_REQUEST, payload, meta);
};
var requestCancelHandout = exports.requestCancelHandout = function requestCancelHandout(payload, meta) {
  return (0, _utils.buildAction)(_variables.CANCEL_HANDOUT_REQUEST, payload, meta);
};
var requestCreateBulletin = exports.requestCreateBulletin = function requestCreateBulletin(payload, meta) {
  return (0, _utils.buildAction)(_variables.CREATE_BULLETIN_REQUEST, payload, meta);
};
var requestCreateNoticeDraft = exports.requestCreateNoticeDraft = function requestCreateNoticeDraft(payload, meta) {
  return (0, _utils.buildAction)(_variables.CREATE_NOTICE_DRAFT_REQUEST, payload, meta);
};
var requestCreateNotice = exports.requestCreateNotice = function requestCreateNotice(payload, meta) {
  return (0, _utils.buildAction)(_variables.CREATE_NOTICE_REQUEST, payload, meta);
};
var requestGetPreviewNotice = exports.requestGetPreviewNotice = function requestGetPreviewNotice(payload, meta) {
  return (0, _utils.buildAction)(_variables.GET_PREVIEW_NOTICE_REQUEST, payload, meta);
};
var requestRepublishNotice = exports.requestRepublishNotice = function requestRepublishNotice(payload, meta) {
  return (0, _utils.buildAction)(_variables.REPUBLISH_NOTICE_REQUEST, payload, meta);
};
var requestDeleteNoticeDraft = exports.requestDeleteNoticeDraft = function requestDeleteNoticeDraft(payload, meta) {
  return (0, _utils.buildAction)(_variables.DELETE_NOTICE_DRAFT_REQUEST, payload, meta);
};
var requestDenyBulletin = exports.requestDenyBulletin = function requestDenyBulletin(payload, meta) {
  return (0, _utils.buildAction)(_variables.DENY_BULLETIN_REQUEST, payload, meta);
};
var requestEditNoticeDraft = exports.requestEditNoticeDraft = function requestEditNoticeDraft(payload, meta) {
  return (0, _utils.buildAction)(_variables.EDIT_NOTICE_DRAFT_REQUEST, payload, meta);
};
var requestGetBulletin = exports.requestGetBulletin = function requestGetBulletin(payload, meta) {
  return (0, _utils.buildAction)(_variables.GET_BULLETIN_REQUEST, payload, meta);
};
var requestGetNotice = exports.requestGetNotice = function requestGetNotice(payload, meta) {
  return (0, _utils.buildAction)(_variables.GET_NOTICE_REQUEST, payload, meta);
};
var requestListBulletins = exports.requestListBulletins = function requestListBulletins(payload, meta) {
  return (0, _utils.buildAction)(_variables.LIST_BULLETINS_REQUEST, payload, meta);
};
var requestListBulletinsRequests = exports.requestListBulletinsRequests = function requestListBulletinsRequests(payload, meta) {
  return (0, _utils.buildAction)(_variables.LIST_BULLETINS_REQUESTS_REQUEST, payload, meta);
};
var requestListNotices = exports.requestListNotices = function requestListNotices(payload, meta) {
  return (0, _utils.buildAction)(_variables.LIST_NOTICES_REQUEST, payload, meta);
};
var requestListExpiredNotices = exports.requestListExpiredNotices = function requestListExpiredNotices(payload, meta) {
  return (0, _utils.buildAction)(_variables.LIST_EXPIRED_NOTICES_REQUEST, payload, meta);
};
var requestListUpcomingNotices = exports.requestListUpcomingNotices = function requestListUpcomingNotices(payload, meta) {
  return (0, _utils.buildAction)(_variables.LIST_UPCOMING_NOTICES_REQUEST, payload, meta);
};